 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M6203 6430 c-114 -21 -236 -86 -315 -167 -44 -46 -108 -149 -108
-175 0 -15 23 -72 35 -86 2 -2 27 22 56 53 98 103 245 170 399 181 178 14 371
-70 479 -206 l38 -48 23 48 c27 58 24 77 -30 158 -71 106 -175 183 -299 222
-75 24 -205 33 -278 20z"/>
<path d="M6230 6171 c-137 -19 -273 -92 -357 -191 -26 -32 -26 -33 -9 -64 40
-72 39 -72 95 -28 107 85 248 128 381 118 120 -9 207 -42 308 -118 59 -44 60
-44 97 24 14 26 13 28 -38 84 -122 135 -297 199 -477 175z"/>
<path d="M5135 5792 l-160 -317 -3 88 -3 87 -64 0 -65 0 0 -182 c0 -209 -7
-248 -53 -280 -37 -27 -132 -32 -184 -10 -58 25 -68 62 -70 272 -1 102 -2 188
-3 193 0 4 -30 7 -66 7 l-67 0 5 -197 c5 -171 8 -206 27 -254 38 -100 102
-140 232 -147 189 -9 284 63 304 231 l7 57 44 0 c24 0 44 -3 44 -7 0 -3 -27
-120 -60 -260 -33 -139 -60 -256 -60 -260 0 -5 65 116 143 267 l142 275 3
-142 3 -143 63 0 64 0 4 188 c3 210 11 237 78 276 48 29 138 28 177 0 41 -31
51 -85 52 -286 l1 -178 65 0 65 0 0 208 c-1 224 -7 280 -37 321 -72 99 -290
94 -376 -8 -30 -36 -40 -28 -33 27 l3 32 -63 0 -64 0 0 -50 c0 -47 -2 -50 -25
-50 -14 0 -25 4 -25 8 0 5 27 129 60 276 34 148 59 270 58 272 -2 2 -76 -140
-163 -314z"/>
<path d="M6880 5550 l0 -480 65 0 65 0 0 480 0 480 -65 0 -65 0 0 -480z"/>
<path d="M8120 6028 c0 -18 0 -936 0 -945 0 -9 20 -13 65 -13 l65 0 0 480 0
480 -65 0 c-36 0 -65 -1 -65 -2z"/>
<path d="M2543 5955 c-6 -11 -48 -99 -93 -195 -46 -96 -103 -218 -128 -270
-24 -52 -53 -115 -64 -140 l-19 -45 -29 65 c-15 36 -78 169 -140 295 -62 127
-118 245 -126 263 -8 17 -18 32 -22 32 -4 0 -34 -111 -66 -247 -32 -137 -78
-332 -102 -435 -24 -103 -44 -192 -44 -198 0 -6 27 -10 64 -10 58 0 64 2 69
23 30 118 107 483 107 504 0 22 7 13 28 -37 16 -36 44 -94 64 -130 19 -36 70
-134 113 -217 42 -84 80 -153 84 -153 9 1 243 452 262 505 17 49 29 49 29 1 0
-17 24 -134 54 -261 l53 -230 67 -3 c36 -2 66 -1 66 2 0 5 -208 874 -214 891
-1 5 -7 1 -13 -10z"/>
<path d="M6153 5899 c-64 -20 -188 -96 -188 -115 0 -5 17 -25 37 -43 l37 -32
38 20 c80 40 151 54 258 49 88 -4 111 -9 169 -36 l68 -32 39 32 c22 17 38 36
36 42 -8 23 -131 96 -193 116 -87 27 -215 26 -301 -1z"/>
<path d="M3130 5654 c-130 -47 -210 -159 -210 -293 0 -80 24 -142 77 -201 62
-69 129 -102 220 -108 138 -9 256 56 313 172 77 156 13 339 -146 417 -63 31
-187 37 -254 13z m165 -105 c25 -7 54 -27 77 -51 132 -143 5 -379 -177 -328
-141 40 -185 233 -76 335 52 50 106 63 176 44z"/>
<path d="M3875 5658 c-75 -26 -125 -90 -125 -159 0 -75 55 -127 195 -184 86
-35 115 -59 115 -97 0 -101 -198 -98 -232 3 -4 11 -108 -42 -108 -55 0 -5 19
-28 43 -51 55 -53 111 -69 214 -63 136 8 203 62 211 167 6 93 -41 144 -181
197 -75 28 -127 66 -127 94 0 72 129 93 174 28 14 -19 15 -19 54 6 53 33 58
43 35 68 -45 52 -184 75 -268 46z"/>
<path d="M6214 5649 c-71 -27 -137 -88 -171 -157 -23 -47 -28 -70 -28 -132 1
-93 27 -154 97 -222 151 -148 416 -104 516 86 23 44 27 63 27 136 0 73 -4 92
-27 136 -76 144 -260 212 -414 153z m184 -104 c169 -71 160 -318 -13 -375 -63
-21 -141 4 -188 60 -86 102 -44 272 78 315 52 18 78 18 123 0z"/>
<path d="M7471 5659 c-166 -50 -261 -237 -205 -402 20 -61 93 -141 158 -175
45 -24 62 -27 146 -27 84 0 101 3 147 28 236 123 234 442 -3 558 -55 27 -182
36 -243 18z m175 -120 c148 -71 146 -288 -3 -360 -78 -38 -170 -9 -224 70 -20
29 -24 47 -24 111 0 65 4 82 25 112 54 80 145 107 226 67z"/>
<path d="M8633 5646 c-60 -28 -114 -95 -135 -166 -19 -65 -16 -194 6 -258 36
-105 128 -172 236 -172 78 0 124 15 166 55 20 19 38 35 40 35 2 0 4 -16 4 -35
l0 -35 65 0 65 0 0 290 0 290 -65 0 -65 0 0 -42 0 -42 -34 37 c-44 48 -91 67
-168 67 -45 0 -77 -7 -115 -24z m208 -97 c72 -24 109 -87 109 -184 0 -150
-113 -240 -232 -187 -57 25 -86 71 -94 148 -17 159 88 266 217 223z"/>
<path d="M3140 4860 c0 -143 1 -150 20 -150 11 0 20 7 20 15 0 14 3 14 25 -1
31 -20 79 -11 100 19 19 28 19 86 0 114 -21 30 -69 39 -100 19 l-25 -16 0 75
c0 68 -2 75 -20 75 -19 0 -20 -7 -20 -150z m128 -22 c7 -7 12 -24 12 -38 0
-32 -17 -50 -46 -50 -44 0 -72 58 -42 88 7 7 24 12 38 12 14 0 31 -5 38 -12z"/>
<path d="M1783 4989 c-30 -16 -44 -60 -29 -90 6 -13 34 -36 61 -51 52 -28 68
-52 53 -80 -11 -23 -76 -25 -84 -3 -7 18 -30 20 -40 4 -14 -23 34 -54 82 -54
54 0 84 28 84 79 0 41 -6 49 -65 83 -38 23 -50 35 -50 54 0 23 3 24 53 22 28
-1 52 1 52 6 0 29 -79 49 -117 30z"/>
<path d="M5753 4974 c-9 -23 13 -40 32 -24 12 10 13 16 4 26 -15 18 -28 18
-36 -2z"/>
<path d="M7623 4974 c-9 -23 13 -40 32 -24 12 10 13 16 4 26 -15 18 -28 18
-36 -2z"/>
<path d="M7040 4925 c0 -24 -5 -35 -15 -35 -8 0 -15 -9 -15 -20 0 -11 7 -20
15 -20 12 0 15 -14 15 -70 0 -63 2 -70 20 -70 18 0 20 7 20 70 0 56 3 70 15
70 8 0 15 9 15 20 0 11 -7 20 -15 20 -10 0 -15 11 -15 35 0 28 -4 35 -20 35
-16 0 -20 -7 -20 -35z"/>
<path d="M2440 4826 c0 -86 20 -111 85 -111 65 0 85 25 85 111 0 50 -3 64 -15
64 -11 0 -15 -12 -16 -42 -2 -87 -19 -108 -75 -94 -22 6 -24 11 -24 71 0 58
-2 65 -20 65 -18 0 -20 -7 -20 -64z"/>
<path d="M3864 4880 c-24 -9 -36 -33 -28 -55 3 -7 26 -22 50 -31 25 -9 43 -22
41 -28 -5 -15 -45 -20 -60 -7 -8 6 -18 6 -27 0 -13 -8 -11 -13 9 -29 43 -36
111 -17 111 32 0 25 -7 32 -45 48 -54 23 -59 43 -11 39 43 -4 57 8 32 27 -22
16 -41 17 -72 4z"/>
<path d="M4520 4877 c-50 -25 -62 -96 -24 -139 33 -36 124 -35 124 2 0 15 -6
17 -38 13 -28 -4 -43 0 -55 12 -40 40 -6 90 55 82 32 -4 38 -2 38 13 0 31 -54
40 -100 17z"/>
<path d="M5138 4878 c-2 -7 -3 -26 -2 -43 1 -16 2 -51 3 -77 1 -35 5 -48 16
-48 11 0 15 12 15 51 0 62 13 89 44 89 20 0 36 16 36 36 0 10 -38 3 -58 -12
-20 -14 -22 -14 -22 0 0 19 -26 21 -32 4z"/>
<path d="M5758 4800 c3 -70 7 -90 18 -90 11 0 14 19 14 90 0 79 -2 90 -17 90
-16 0 -18 -10 -15 -90z"/>
<path d="M6330 4755 c0 -128 1 -135 20 -135 18 0 20 7 20 60 l0 60 25 -16 c33
-22 84 -8 103 26 14 28 10 93 -8 115 -19 23 -68 29 -95 11 -22 -15 -25 -15
-25 -1 0 8 -9 15 -20 15 -19 0 -20 -7 -20 -135z m128 83 c7 -7 12 -24 12 -38
0 -14 -5 -31 -12 -38 -7 -7 -24 -12 -38 -12 -14 0 -31 5 -38 12 -7 7 -12 24
-12 38 0 14 5 31 12 38 7 7 24 12 38 12 14 0 31 -5 38 -12z"/>
<path d="M7629 4886 c-2 -2 -3 -43 -1 -90 3 -66 7 -86 18 -86 11 0 14 19 14
90 0 68 -3 90 -13 90 -8 0 -16 -2 -18 -4z"/>
<path d="M8244 4881 c-55 -24 -69 -92 -30 -138 20 -23 32 -28 71 -28 39 0 51
5 71 28 30 35 31 78 2 112 -25 28 -79 40 -114 26z m84 -43 c7 -7 12 -24 12
-38 0 -14 -5 -31 -12 -38 -32 -32 -115 5 -101 45 13 40 74 58 101 31z"/>
<path d="M8900 4800 c0 -83 1 -90 20 -90 18 0 20 7 20 58 0 65 11 82 52 82 35
0 48 -24 48 -89 0 -39 4 -51 15 -51 12 0 15 15 15 75 0 68 -2 76 -25 91 -29
19 -72 12 -92 -14 -11 -15 -12 -15 -13 6 0 15 -6 22 -20 22 -19 0 -20 -7 -20
-90z"/>
<path d="M3555 4588 c-3 -13 -10 -54 -15 -93 -6 -38 -14 -80 -17 -92 -4 -19
-1 -23 20 -23 29 0 27 -5 51 148 l13 82 -23 0 c-16 0 -25 -7 -29 -22z"/>
<path d="M3664 4596 c-8 -21 3 -36 27 -36 15 0 20 6 17 22 -4 27 -35 37 -44
14z"/>
<path d="M3997 4510 c-10 -54 -17 -106 -17 -115 0 -18 36 -20 43 -2 4 10 7 10
18 0 20 -19 73 -16 93 6 9 10 19 34 23 54 4 27 1 39 -17 57 -21 21 -41 23 -81
6 -15 -6 -16 -2 -8 36 11 60 12 58 -14 58 -22 0 -25 -6 -40 -100z m99 -34 c15
-12 17 -18 8 -33 -14 -22 -54 -29 -65 -11 -12 18 -11 24 7 42 19 20 25 20 50
2z"/>
<path d="M4235 4578 c-3 -18 -12 -70 -19 -115 l-13 -83 24 0 c25 0 26 2 43
110 6 36 13 77 16 93 5 24 3 27 -20 27 -20 0 -26 -6 -31 -32z"/>
<path d="M5205 4556 l-7 -54 -28 16 c-23 13 -32 14 -58 3 -43 -18 -63 -78 -38
-115 15 -23 21 -25 83 -23 l68 2 11 70 c26 157 26 155 0 155 -22 0 -25 -5 -31
-54z m-29 -80 c26 -20 7 -51 -32 -51 -35 0 -46 32 -18 52 22 16 26 16 50 -1z"/>
<path d="M5912 4598 c-15 -18 1 -41 24 -36 23 4 26 41 3 46 -9 1 -21 -3 -27
-10z"/>
<path d="M6040 4590 c-11 -11 -20 -29 -20 -40 0 -11 -7 -20 -15 -20 -17 0 -20
-21 -5 -36 6 -6 6 -30 1 -62 -8 -50 -7 -52 15 -52 24 0 28 9 39 78 5 32 11 42
26 42 10 0 19 7 19 15 0 8 -7 15 -15 15 -22 0 -18 31 5 37 11 3 20 14 20 24 0
26 -44 25 -70 -1z"/>
<path d="M7617 4510 c-22 -129 -22 -130 8 -130 21 0 25 5 25 30 0 17 4 30 8
30 4 0 16 -13 25 -30 12 -20 26 -30 43 -30 29 0 30 6 4 45 l-19 30 36 37 37
38 -29 0 c-19 0 -41 -11 -63 -32 l-34 -33 6 38 c3 20 9 53 12 72 6 32 4 35
-18 35 -24 0 -26 -5 -41 -100z"/>
<path d="M3126 4573 c-10 -48 -26 -157 -26 -175 0 -13 8 -18 25 -18 19 0 25 5
26 23 0 12 4 31 8 42 8 17 11 14 26 -22 15 -39 21 -43 51 -43 l34 0 -26 40
c-15 22 -25 42 -23 43 2 2 16 11 32 20 21 14 27 25 27 52 0 45 -29 65 -96 65
-49 0 -53 -2 -58 -27z m89 -27 c16 -12 17 -16 6 -30 -21 -25 -51 -20 -51 7 0
39 14 46 45 23z"/>
<path d="M5435 4575 c-35 -34 -31 -58 15 -95 22 -17 40 -36 40 -41 0 -18 -34
-22 -60 -7 -25 14 -28 14 -40 -5 -10 -17 -10 -23 6 -34 24 -18 95 -16 122 3
15 11 22 25 22 50 0 29 -6 38 -35 55 -35 21 -45 44 -22 52 6 2 20 0 31 -5 13
-7 21 -6 27 3 13 21 11 27 -10 39 -33 17 -71 11 -96 -15z"/>
<path d="M6376 4568 c-3 -18 -10 -60 -16 -92 -15 -88 -14 -96 14 -96 26 0 27
3 42 100 l7 45 33 -72 c30 -67 35 -73 63 -73 34 0 34 0 51 110 15 100 14 110
-14 110 -22 0 -26 -6 -31 -47 -14 -100 -18 -101 -50 -24 -28 67 -32 71 -62 71
-28 0 -33 -4 -37 -32z"/>
<path d="M6160 4556 c0 -7 -10 -21 -22 -30 -20 -14 -21 -17 -7 -26 13 -8 15
-18 8 -52 -12 -64 -11 -68 15 -68 22 0 25 5 32 56 5 43 11 58 25 61 23 6 25
33 3 33 -11 0 -14 6 -9 20 5 16 2 20 -19 20 -15 0 -26 -6 -26 -14z"/>
<path d="M6860 4550 c0 -11 -7 -20 -15 -20 -17 0 -20 -25 -5 -35 6 -4 7 -28 4
-61 -6 -50 -5 -54 14 -54 21 0 26 9 37 73 3 21 13 41 21 44 16 6 19 33 4 33
-5 0 -10 9 -10 20 0 15 -7 20 -25 20 -18 0 -25 -5 -25 -20z"/>
<path d="M3346 4509 c-20 -16 -26 -29 -26 -58 0 -49 24 -71 76 -71 73 0 82 32
10 34 -54 2 -38 21 19 24 51 3 55 5 55 27 0 58 -82 85 -134 44z m80 -26 c17
-17 16 -18 -15 -21 -40 -4 -48 2 -31 23 16 19 25 19 46 -2z"/>
<path d="M3659 4528 c-1 -2 -7 -36 -13 -76 l-12 -73 25 3 c24 3 27 8 37 76 11
72 11 72 -13 72 -12 0 -23 -1 -24 -2z"/>
<path d="M3775 4505 c-29 -28 -34 -85 -9 -109 18 -19 65 -21 82 -4 9 9 12 9
12 0 0 -7 11 -12 24 -12 22 0 25 6 35 63 6 35 11 69 11 75 0 18 -38 14 -48 -4
-8 -14 -10 -14 -21 0 -20 23 -59 19 -86 -9z m87 -27 c26 -21 6 -60 -29 -56
-34 4 -43 36 -17 55 22 16 26 16 46 1z"/>
<path d="M4346 4509 c-32 -25 -37 -86 -8 -112 20 -18 88 -23 112 -7 22 13 4
24 -40 25 -60 0 -48 20 15 23 51 3 55 5 55 27 0 58 -82 85 -134 44z m94 -39
c0 -6 -16 -10 -35 -10 -38 0 -46 13 -18 29 19 10 53 -2 53 -19z"/>
<path d="M4646 4504 c-30 -30 -34 -69 -10 -102 17 -25 62 -30 83 -9 11 10 14
10 18 0 7 -19 41 -16 47 5 3 9 8 43 12 75 6 53 5 57 -14 57 -11 0 -22 -5 -24
-11 -3 -9 -9 -9 -25 0 -34 17 -58 13 -87 -15z m90 -28 c26 -20 7 -51 -32 -51
-35 0 -46 32 -18 52 22 16 26 16 50 -1z"/>
<path d="M4861 4467 c-15 -85 -15 -87 13 -87 20 0 25 6 30 45 7 47 31 70 55
55 10 -6 12 -20 7 -54 -7 -45 -6 -46 18 -46 20 0 26 5 26 23 0 12 3 38 6 58
10 57 -42 92 -80 53 -15 -14 -16 -14 -16 0 0 10 -9 16 -24 16 -22 0 -25 -6
-35 -63z"/>
<path d="M5601 4463 c10 -38 19 -71 19 -75 0 -5 11 -8 24 -8 19 0 28 9 43 45
10 25 21 45 24 45 4 0 10 -20 13 -45 6 -39 10 -45 31 -45 20 0 31 12 60 70 19
38 35 72 35 75 0 4 -10 5 -22 3 -16 -2 -30 -17 -43 -45 -11 -23 -23 -42 -26
-43 -3 0 -10 19 -15 42 -13 63 -38 62 -68 -2 l-24 -51 -12 50 c-10 43 -16 51
-35 51 l-24 0 20 -67z"/>
<path d="M5897 4508 c-3 -13 -9 -47 -13 -75 -6 -52 -6 -53 18 -53 23 0 26 5
32 53 4 28 10 62 13 75 4 18 1 22 -20 22 -17 0 -26 -7 -30 -22z"/>
<path d="M6665 4518 c-48 -28 -60 -83 -24 -119 16 -16 30 -19 67 -17 34 2 47
7 47 18 0 11 -11 15 -39 15 -22 0 -41 5 -43 13 -4 9 12 12 56 12 55 0 61 2 61
20 0 51 -76 86 -125 58z m65 -33 c17 -21 9 -27 -31 -23 -31 3 -32 4 -15 21 21
21 30 21 46 2z"/>
<path d="M6961 4463 c10 -38 19 -71 19 -75 0 -5 11 -8 24 -8 19 0 28 9 43 45
10 25 21 45 24 45 4 0 10 -20 13 -45 6 -39 10 -45 31 -45 20 0 31 12 60 70 19
38 35 72 35 75 0 4 -10 5 -22 3 -16 -2 -30 -17 -43 -45 -11 -23 -23 -42 -26
-43 -3 0 -10 19 -15 42 -13 63 -38 62 -68 -2 l-24 -51 -12 50 c-10 43 -16 51
-35 51 l-24 0 20 -67z"/>
<path d="M7290 4523 c-51 -19 -69 -93 -32 -126 26 -24 93 -22 120 3 31 29 31
93 0 114 -22 16 -59 19 -88 9z m56 -47 c25 -19 9 -50 -28 -54 -33 -4 -45 24
-22 49 21 23 25 23 50 5z"/>
<path d="M7466 4503 c-3 -16 -8 -49 -12 -75 -6 -47 -6 -48 20 -48 20 0 26 5
26 23 0 43 13 68 41 83 16 8 29 21 29 29 0 17 -26 20 -35 5 -3 -5 -11 -10 -16
-10 -6 0 -7 5 -4 10 3 6 -5 10 -19 10 -19 0 -26 -6 -30 -27z"/>
<path d="M1882 4513 c-116 -3 -152 -7 -149 -16 2 -7 21 -12 45 -12 23 1 256 2
517 3 415 2 475 4 475 17 0 13 -47 15 -367 13 -203 -1 -437 -4 -521 -5z"/>
<path d="M8208 4513 c-128 -3 -148 -6 -148 -20 0 -14 9 -15 68 -10 37 4 268 7
515 7 391 0 447 2 447 15 0 13 -47 15 -367 13 -203 -1 -434 -4 -515 -5z"/>
</g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}